import Vue from 'vue';
import Router from 'vue-router';
import BannerList from '../components/BannerList.vue';
import AwardsList from '../components/AwardsList.vue';
import TeamsList from '../components/TeamsList.vue';
import ProjectsList from '../components/ProjectsList.vue';
import CategoryList from '../components/CategoryList.vue';
import ProductCreate from '../components/ProductCreate.vue';
import TeamsCreate from '../components/TeamsCreate.vue';
import AwardsCreate from '../components/AwardsCreate.vue';
import ProjectsCreate from '../components/ProjectsCreate.vue';
import AppLogin from '../components/AppLogin.vue';
import EditableTexts from '../components/EditableTexts.vue';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
  routes: [
    {
        path: '/',
        redirect: '/login' // 默认重定向到登录页面
    },
    {
        path: '/login',
        name: 'AppLogin',
        component: AppLogin
    },
    {
      path: '/products/list',
      name: 'ProductList',
      component: BannerList,
      meta: { requiresAuth: true }
    },
    {
      path: '/products/awards',
      name: 'AwardList',
      component: AwardsList,
      meta: { requiresAuth: true }
    },
    {
      path: '/products/teams',
      name: 'TeamsList',
      component: TeamsList,
      meta: { requiresAuth: true }
    },
    {
      path: '/products/projects',
      name: 'ProjectsList',
      component: ProjectsList,
      meta: { requiresAuth: true }
    },
    {
      path: '/products/categories',
      name: 'CategoryList',
      component: CategoryList,
      meta: { requiresAuth: true }
    },
    // {
    //   path: '/users',
    //   component: UserDetails
    // },
    // {
    //   path: '/orders',
    //   component: OrderManagement
    // },
    {
      path: '/products/create',
      component: ProductCreate,
      meta: { requiresAuth: true }
    },
    {
      path: '/products/teamsCreate',
      name: 'TeamsCreate',
      component: TeamsCreate,
      meta: { requiresAuth: true }
    },
    {
      path: '/products/awardsCreate',
      name: 'AwardsCreate',
      component: AwardsCreate,
      meta: { requiresAuth: true }
    },
    {
      path: '/products/projectsCreate',
      name: 'ProjectsCreate',
      component: ProjectsCreate,
      meta: { requiresAuth: true }
    },
    {
      path: '/products/editableTexts',
      name: 'EditableTexts',
      component: EditableTexts,
      meta: { requiresAuth: true }
    },
    {
      path: '/edit-project/:id',
      name: 'EditProject',
      component: () => import('@/components/ProjectEdit.vue'),
      props: true,
      meta: { requiresAuth: true }
    },
    // {
    //   path: '/products/:id',
    //   component: ProductDetails
    // },
    // {
    //   path: '/products/edit/:id',
    //   component: ProductEdit
    // }
  ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!localStorage.getItem('userLoggedIn')) {
        // next({ name: 'AppLogin' });
        next({ path: '/login', query: { redirect: to.fullPath } });
      } else {
        next();
      }
    } else {
      next();
    }
  });

// router.beforeEach((to, from, next) => {
//     console.log(to.name);
//     const isAuthenticated = false; // 此處添加你的登入狀態檢查邏輯
//     if (to.name !== 'AppLogin' && !isAuthenticated) {
//       next({ name: 'AppLogin' });
//     } else {
//       next();
//     }
// });


export default router;