<template>
    <div class="banner-list">
      <el-table-draggable v-model="banners" @onEnd="onEnd">
        <el-table :data="banners" row-key="index" border style="width: 100%">
          <el-table-column prop="index" label="排序" width="80"/>
          <el-table-column label="圖片">
            <template slot-scope="scope">
              <img :src="'/images/' + scope.row.image" class="banner-image" />
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <!-- <el-switch
                v-model="scope.row.enabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="toggleBanner(scope.row)">
              </el-switch> -->
              <el-button type="danger" size="mini" @click="deleteBanner(scope.row)">刪除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-table-draggable>
    </div>
  </template>
  
  
  <script>
import ElTableDraggable from 'el-table-draggable';

  export default {
    components: {
      ElTableDraggable
    },
    data() {
      return {
        banners: []  // 儲存從API獲取的banner數據
      };
    },
    created() {
      this.fetchBanners();
    },
    methods: {
        fetchBanners() {
            // 假設你的API端點是 '/api/banners'
            this.$axios.get('/images')
            .then(response => {
                this.banners = response.data.map((banner, index) => ({
                ...banner,
                index: index + 1  // 添加排序序號
                }));
            })
            .catch(error => {
                console.error('Error fetching banners:', error);
                this.$message.error('獲取Banner數據失敗');
            });
        },
        toggleBanner(banner) {
            // 這邊可以添加一個API調用更新banner的啟用/禁用狀態
            console.log('Toggled banner:', banner);
        },
        deleteBanner(banner) {
            this.$confirm('確定要刪除這個Banner圖片嗎？', '警告', {
                confirmButtonText: '確定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.delete(`/images/${banner._id}`)
                .then(() => {
                    this.$message.success('Banner圖片已刪除');
                    this.fetchBanners();  // 重新獲取最新的banner列表
                })
                .catch(error => {
                    console.error('Error deleting banner:', error);
                    this.$message.error('刪除Banner圖片失敗');
                });
            }).catch(() => {
                this.$message.info('取消刪除操作');
            });
        },
        onEnd(event) {  // eslint-disable-line no-unused-vars
          this.$nextTick(() => {
            this.saveBannerOrder();
          });
        },
        saveBannerOrder() {
          const orderedBanners = this.banners.map((banner, index) => ({
            _id: banner._id,
            order: index + 1
          }));
          this.$axios.put('/images/order/update', orderedBanners)
          .then(() => {
            this.$message.success('Banner順序以保存');
            this.fetchBanners();
          })
          .catch(error => {
            console.error('Error saving Banner order:', error);
            this.$message.error('保存Banner順序失敗');
          });
        }
    }
  }
  </script>
  
  <style scoped>
  .banner-image {
    width: 100px;
    height: auto;
  }
  .el-button {
  margin-left: 10px;  /* 刪除按鈕添加左邊距 */
}
  </style>
  