<template>
    <div>
        <el-upload
        :action="uploadActionUrl"
        list-type="picture"
        :before-upload="beforeUpload"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        name="image"
        drag
        multiple
        accept=".jpg, .jpeg, .png, .svg"
        ref="upload"
        :auto-upload="false">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">將文件拖到此處，或<em>點擊上傳</em></div>
        <div class="el-upload__tip" slot="tip">只能上傳jpg/png/svg文件，且不超過2MB</div>
        </el-upload>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上傳到伺服器</el-button>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false
      };
    },
    computed: {
      uploadActionUrl() {
        // 这里使用全局变量 $apiUrl 来构建完整的 URL
        return `${this.$apiUrl}/upload`;
      }
    },
    methods: {
      submitUpload() {
        this.$refs.upload.submit();
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2; // 限制檔案大小為2MB
        if (!isLt2M) {
          this.$message.error('上傳文件大小不能超過 2MB!');
        }
        return isLt2M;
      },
      handlePictureCardPreview(file) {
        console.log(file);
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    }
  }
</script>
<style scoped>
  .el-upload-list--picture img {
    object-fit: cover;
  }
</style>

<!-- <template>
    <el-upload
    class="avatar-uploader"
    action="http://127.0.0.1:3000/uploadAwards"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    :data="{year: 2015}"
    name="image">
    <img v-if="imageUrl" :src="imageUrl" class="avatar">
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
</template>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>

<script>
  export default {
    data() {
      return {
        imageUrl: ''
      };
    },
    methods: {
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
    }
  }
</script> -->


<!-- <template>
  <div>
    <el-upload
      action="http://127.0.0.1:3000/uploadAwards"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :data="{year: 2000, grade: 'WINNER'}"
      name="image">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false
      };
    },
    methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    }
  }
</script> -->


<!-- <template>
  <div>
    <el-form ref="uploadForm" :model="form" @submit.native.prevent="handleSubmit">
      <el-form-item label="年份">
        <el-input v-model.number="form.year" placeholder="請輸入年份"></el-input>
      </el-form-item>
      <el-form-item label="等级">
        <el-input v-model="form.grade" placeholder="請輸入等級"></el-input>
      </el-form-item>
      <el-form-item>
        <el-upload
          ref="upload"
          action="http://127.0.0.1:3000/uploadAwards"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="beforeUpload"
          :data="form"
          name="image"
          :auto-upload="false">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      form: {
        year: null,
        grade: ''
      }
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload() {
      if (!this.form.year || !this.form.grade) {
        this.$message.error('請先填寫完整年份和等级信息');
        return false; // 阻止文件上傳
      }
      return true; // 允許上傳
    },
    submitForm() {
      if (this.beforeUpload() && this.$refs.upload) {
        console.log("submit");
        this.$refs.upload.submit(); // 觸發上傳
      } else {
        console.error('Upload component is not available');
      }
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 10px;
}
</style> -->
