<template>
    <div>
      <el-table :data="editableTexts" border style="width: 100%">
        <el-table-column prop="key" label="區域標識" width="180"/>
        <el-table-column label="內容">
          <template v-slot="{ row }">
            <el-input v-model="row.content" type="textarea" :autosize="{ minRows: 2, maxRows: 6 }"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template v-slot="{ row }">
            <el-button type="primary" size="mini" @click="updateText(row)">保存</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        editableTexts: []  // 儲存從API獲取的文本數據
      };
    },
    created() {
      this.fetchEditableTexts();
    },
    methods: {
      fetchEditableTexts() {
        this.$axios.get('/editableTexts')
          .then(response => {
            this.editableTexts = response.data;
          })
          .catch(error => {
            console.error('Error fetching editable texts:', error);
            this.$message.error('獲取文本數據失敗');
          });
      },
      updateText(text) {
        this.$axios.put(`/editableTexts/${text.key}`, { content: text.content })
          .then(() => {
            this.$message.success('文本已更新');
          })
          .catch(error => {
            console.error('Error updating text:', error);
            this.$message.error('更新文本失敗');
          });
      }
    }
  }
  </script>
  
  <style scoped>
  .el-input {
    width: 100%;
  }
  </style>
  