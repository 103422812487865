<template>
  <div class="awards-list">
    <ElTableDraggable v-model="awards" @onEnd="onEnd">
      <el-table :data="awards" row-key="index" border style="width: 100%">
        <el-table-column prop="index" label="排序" width="80"/>
        <el-table-column label="圖片">
          <template slot-scope="scope">
            <img :src="'/images/' + scope.row.image" class="award-image" />
          </template>
        </el-table-column>
        <el-table-column prop="year" label="年份" width="120"/>
        <el-table-column prop="grade" label="等級" width="180"/>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openEditDialog(scope.row)">編輯</el-button>
            <el-button type="danger" size="mini" @click="deleteAward(scope.row)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </ElTableDraggable>
    <el-dialog title="編輯獎項" :visible.sync="editDialogVisible" width="30%">
      <el-form :model="editAward">
        <el-form-item label="年份">
          <el-input v-model="editAward.year"></el-input>
        </el-form-item>
        <el-form-item label="等級">
          <el-input v-model="editAward.grade"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateAward">確認</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import ElTableDraggable from "el-table-draggable";

export default {
  components: {
    ElTableDraggable
  },
  data() {
    return {
      awards: [],  // 儲存從API獲取的獎項數據
      editDialogVisible: false,  // 控制編輯對話框的顯示
      editAward: {},  // 儲存當前編輯的獎項數據
    };
  },
  created() {
    this.fetchAwards();
  },
  methods: {
    fetchAwards() {
      this.$axios.get('/uploadAwards')
        .then(response => {
          this.awards = response.data.map((award, index) => ({
            ...award,
            index: index + 1  // 添加排序序號
          }));
        })
        .catch(error => {
          console.error('Error fetching awards:', error);
          this.$message.error('獲取獎項數據失敗');
        });
    },
    openEditDialog(award) {
      this.editAward = { ...award };  // 複製當前編輯的獎項數據
      this.editDialogVisible = true;  // 顯示編輯對話框
    },
    onEnd(event) {  // eslint-disable-line no-unused-vars
      this.$nextTick(()=> {
        this.saveAwardOrder();
      });
    },
    saveAwardOrder() {
      const orderedAwards = this.awards.map((award, index) => ({
        _id: award._id,
        order: index + 1
      }));
      this.$axios.put('/awards/order/update', orderedAwards)
        .then(() => {
          this.$message.success('獎項順序以保存');
          this.fetchAwards();
        })
        .catch(error => {
          console.error('Error saving awards order:', error);
          this.$message.error('保存獎項順序失敗');
        });
    },
    updateAward() {
      this.$axios.put(`/uploadAwards/${this.editAward._id}`, this.editAward)
        .then(() => {
          this.$message.success('獎項已更新');
          this.fetchAwards();  // 重新獲取最新的獎項列表
          this.editDialogVisible = false;  // 隱藏編輯對話框
        })
        .catch(error => {
          console.error('Error updating award:', error);
          this.$message.error('更新獎項失敗');
        });
    },
    deleteAward(award) {
      this.$confirm('確定要刪除這個獎項嗎？', '警告', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.delete(`/uploadAwards/${award._id}`)
          .then(() => {
            this.$message.success('獎項已刪除');
            this.fetchAwards();  // 重新獲取最新的獎項列表
          })
          .catch(error => {
            console.error('Error deleting award:', error);
            this.$message.error('刪除獎項失敗');
          });
      }).catch(() => {
        this.$message.info('取消刪除操作');
      });
    }
  }
}
</script>

<style scoped>
.award-image {
  width: 100px;
  height: auto;
}
</style>
