import { render, staticRenderFns } from "./AwardsCreate.vue?vue&type=template&id=ef502e6a&scoped=true"
import script from "./AwardsCreate.vue?vue&type=script&lang=js"
export * from "./AwardsCreate.vue?vue&type=script&lang=js"
import style0 from "./AwardsCreate.vue?vue&type=style&index=0&id=ef502e6a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef502e6a",
  null
  
)

export default component.exports