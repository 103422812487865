import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router';
import VueRouter from 'vue-router';
import Axios from 'axios';

// Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter)


// 配置全局的 Axios 默认值
// Axios.defaults.baseURL = 'http://your-api-url.com/api';
// Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // 如果需要的话
// Axios.defaults.baseURL = 'http://localhost:3000';
Axios.defaults.baseURL = 'http://54.180.230.243:3000';
Vue.prototype.$axios = Axios; // 将 Axios 实例添加到 Vue 原型上，使其在组件中通过 this.$axios 访问

// Vue.prototype.$apiUrl = 'http://127.0.0.1:3000';
Vue.prototype.$apiUrl = "http://54.180.230.243:3000";

// Axios.defaults.baseURL = 'http://45.141.118.109/api';
// Vue.prototype.$apiUrl = 'http://45.141.118.109/api';

Axios.defaults.baseURL = 'https://shishandesign.com.tw/api';
Vue.prototype.$apiUrl = 'https://shishandesign.com.tw/api';

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
