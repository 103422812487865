<template>
    <div class="category-list">
      <el-button type="primary" @click="openDialog('add')">新增</el-button>
      <el-table :data="categories" border>
        <el-table-column prop="index" label="排序" width="80" />
        <el-table-column prop="name" label="名稱" width="180" />
        <el-table-column label="操作" width="200">
          <template v-slot="{ row }">
            <el-button type="primary" size="mini" @click="openDialog('edit', row)">編輯</el-button>
            <el-button type="danger" size="mini" @click="deleteCategory(row._id)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :visible.sync="dialogVisible" title="分類">
        <el-form :model="form">
            <el-form-item label="名稱">
            <el-input v-model="form.name"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitForm">確定</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        categories: [], // 存儲從API獲取的分類數據
        dialogVisible: false,
        form: {
          _id: null,
          name: ''
        },
        action: 'add'
      };
    },
    created() {
      this.fetchCategories();
    },
    methods: {
      async fetchCategories() {
        try {
          const response = await this.$axios.get('/categories');
          this.categories = response.data.map((category, index) => ({
            ...category,
            index: index + 1 // 添加排序序號
          }));
        } catch (error) {
          console.error('Error fetching categories:', error);
          this.$message.error('獲取分類數據失敗');
        }
      },
      openDialog(action, category = { _id: null, name: '' }) {
        this.action = action;
        this.form = { ...category };
        this.dialogVisible = true;
      },
      async submitForm() {
        try {
          if (this.action === 'add') {
            await this.$axios.post('/categories', this.form);
            this.$message.success('分類已新增');
          } else if (this.action === 'edit') {
            await this.$axios.put(`/categories/${this.form._id}`, this.form);
            this.$message.success('分類已更新');
          }
          this.dialogVisible = false;
          this.fetchCategories();
        } catch (error) {
          console.error('Error saving category:', error);
          this.$message.error('保存分類失敗');
        }
      },
      async deleteCategory(id) {
        try {
          await this.$axios.delete(`/categories/${id}`);
          this.$message.success('分類已刪除');
          this.fetchCategories(); // 重新獲取最新的分類列表
        } catch (error) {
          console.error('Error deleting category:', error);
          this.$message.error('刪除分類失敗');
        }
      }
    }
  };
</script>
  
<style scoped>
  .category-list {
    padding: 20px;
  }

  .el-table {
    width: 460px;
  }
</style>
  