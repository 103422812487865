<template>
    <div>
      <el-form ref="uploadForm" :model="form" @submit.native.prevent="handleSubmit">
        <el-form-item label="姓名">
          <el-input v-model="form.name" placeholder="請輸入姓名"></el-input>
        </el-form-item>
        <el-form-item label="職稱">
          <el-input v-model="form.title" placeholder="請輸入職稱"></el-input>
        </el-form-item>
        <el-form-item>
          <el-upload
            ref="upload"
            :action="uploadActionUrl"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :data="form"
            name="image"
            :auto-upload="false">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false,
        form: {
          name: null,
          title: ''
        }
      };
    },
    computed: {
      uploadActionUrl() {
        // 这里使用全局变量 $apiUrl 来构建完整的 URL
        return `${this.$apiUrl}/uploadTeams`;
      }
    },
    methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      beforeUpload() {
        if (!this.form.name || !this.form.title) {
          this.$message.error('請先填寫完整姓名和職稱信息');
          return false; // 阻止文件上傳
        }
        return true; // 允許上傳
      },
      submitForm() {
        if (this.beforeUpload() && this.$refs.upload) {
          console.log("submit");
          this.$refs.upload.submit(); // 觸發上傳
        } else {
          console.error('Upload component is not available');
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .el-form {
    width: 50%;
  }
  
  .el-form-item {
    margin-bottom: 10px;
  }
  </style>