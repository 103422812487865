<template>
    <el-aside width="250px">
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#333744"
        text-color="#fff"
        active-text-color="#ffd04b"
        :default-openeds="['2']">
        <!-- <el-submenu index="1">
          <template slot="title"><i class="el-icon-menu"></i>User</template>
          <el-menu-item-group>
            <el-menu-item index="1-1" @click="routeTo('/users')">Details</el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
        <el-submenu index="2" default-opened>
          <template slot="title"><i class="el-icon-setting"></i>Product</template>
          <el-menu-item-group>
            <el-menu-item index="2-1" @click="routeTo('/products/list')">BannerList</el-menu-item>
            <el-menu-item index="2-2" @click="routeTo('/products/create')">BannerCreate</el-menu-item>
            <!-- <el-menu-item index="2-3" @click="routeTo('/products/:id')">Details</el-menu-item>
            <el-menu-item index="2-4" @click="routeTo('/products/edit/:id')">Edit</el-menu-item> -->
            <el-menu-item index="2-5" @click="routeTo('/products/awards')">AwardsList</el-menu-item>
            <el-menu-item index="2-6" @click="routeTo('/products/teams')">TeamsList</el-menu-item>
            <el-menu-item index="2-7" @click="routeTo('/products/projects')">ProjectsList</el-menu-item>
            <el-menu-item index="2-8" @click="routeTo('/products/teamsCreate')">TeamsCreate</el-menu-item>
            <el-menu-item index="2-9" @click="routeTo('/products/awardsCreate')">AwardsCreate</el-menu-item>
            <el-menu-item index="2-10" @click="routeTo('/products/projectsCreate')">ProjectsCreate</el-menu-item>
            <el-menu-item index="2-11" @click="routeTo('/products/categories')">CategoryList</el-menu-item>
            <el-menu-item index="2-12" @click="routeTo('/products/editableTexts')">EditableTexts</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- <el-submenu index="3">
          <template slot="title"><i class="el-icon-shopping-cart-2"></i>Order</template>
          <el-menu-item-group>
            <el-menu-item index="3-1" @click="routeTo('/orders')">Management</el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
      </el-menu>
    </el-aside>
  </template>
  
  <script>
  export default {
    methods: {
      routeTo(path) {
        if (this.$route.path !== path) {
          this.$router.push(path).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
          });
        }
      }
    }
  }
</script>
  