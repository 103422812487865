<template>
    <div>
      <el-form ref="uploadForm" :model="form" @submit.native.prevent="handleSubmit">
        <el-form-item label="標題">
          <el-input v-model="form.title" placeholder="請輸入標題"></el-input>
        </el-form-item>
        <el-form-item label="副標題">
          <el-input v-model="form.subtitle" placeholder="請輸入副標題"></el-input>
        </el-form-item>
        <el-form-item label="類別">
          <el-select v-model="form.category" placeholder="選擇類別">
            <el-option
              v-for="category in categories"
              :key="category._id"
              :label="category.name"
              :value="category.name"
            ></el-option>
          </el-select>
          <el-button type="primary" @click="navigateToCategoryManagement">管理</el-button>
        </el-form-item>
        <el-form-item>
          <el-upload
            ref="upload"
            :action="uploadActionUrl"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :data="form"
            name="image"
            drag
            list-type="picture"
            :auto-upload="false">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      form: {
        title: '',
        subtitle: '',
        category: ''
      },
      categories: [] // 新增的 data 屬性
    };
  },
  created() {
    // 在組件創建時發送 API 請求以獲取類別資料
    this.fetchCategories();
  },
  computed: {
      uploadActionUrl() {
        // 这里使用全局变量 $apiUrl 来构建完整的 URL
        return `${this.$apiUrl}/projects`;
      }
    },
  methods: {
    fetchCategories() {
      // 發送 API 請求以獲取類別資料
      this.$axios.get('/categories')
        .then(response => {
          this.categories = response.data; // 將回應資料賦值給 categories
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });
    },
    navigateToCategoryManagement() {
      // 路由導航到新增類別頁面
      this.$router.push({ path: '/products/categories' });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload() {
      if (!this.form.title || !this.form.subtitle || !this.form.category) {
        this.$message.error('請先填寫完整標題、副標題和類別信息');
        return false; // 阻止文件上傳
      }
      return true; // 允許上傳
    },
    submitForm() {
      if (this.beforeUpload() && this.$refs.upload) {
          console.log("submit");
          this.$refs.upload.submit(); // 觸發上傳
        } else {
          console.error('Upload component is not available');
        }
    }
  }
}
</script>

<style scoped>
  .el-form {
    width: 50%;
  }
  
  .el-form-item {
    margin-bottom: 10px;
  }

  .el-select {
    padding-right: 12px;
  }
</style>