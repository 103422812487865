<template>
  <el-container>
    <app-sidebar v-if="showSidebar"></app-sidebar> <!-- 可见性由 showSidebar 控制 -->
    <el-main>
      <router-view @login-success="handleLoginSuccess"></router-view>
    </el-main>
  </el-container>
</template>


<script>
import AppSidebar from './components/AppSidebar.vue'

export default {
  name: 'App',
  components: {
    AppSidebar
  },
  data() {
    return {
      showSidebar: false  // 控制侧边栏是否显示
    };
  },
  created() {
    this.showSidebar = localStorage.getItem('showSidebar') === 'true';  // 从localStorage读取侧边栏状态
  },
  methods: {
    handleLoginSuccess() {
      this.showSidebar = true;  // 登录成功时显示侧边栏
      localStorage.setItem('showSidebar', 'true');  // 存储侧边栏显示状态
    },
    logout() {
      this.showSidebar = false;  // 登出时隐藏侧边栏
      localStorage.setItem('showSidebar', 'false');  // 更新存储的状态
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.el-container {
  display: flex;  /* 設定為Flex容器 */
  width: 100%;    /* 容器寬度為100% */
}

.el-main {
  width: 80%;     /* 主內容區寬度為80% */
  display: block; /* 防止內容包裹不填滿 */
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
