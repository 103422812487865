<template>
  <div class="projects-list">
    <ElTableDraggable v-model="projects" @onEnd="onEnd">
      <el-table :data="projects" row-key="index" border height="90vh" style="width: 100%">
        <el-table-column prop="index" label="排序" width="80"/>
        <el-table-column label="圖片">
          <template v-slot="{ row }">
            <el-image 
              :src="'/images/' + row.image" 
              style="width: 150px; height: 100px"
              :preview-src-list="'/images/' + row.image"
              >
            </el-image>
            <!-- <img :src="'/images/' + row.image" class="project-image" /> -->
          </template>
        </el-table-column>
        <el-table-column prop="title" label="標題" width="180"/>
        <el-table-column prop="subtitle" label="副標題" width="180"/>
        <el-table-column prop="category" label="類別" width="180"/>
        <el-table-column label="標籤" width="100">
          <template v-slot="{ row }">
            <el-switch v-model="row.flag" @change="toggleFlag(row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="啟用" width="100">
          <template v-slot="{ row }">
            <el-switch v-model="row.enabled" @change="toggleProject(row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="隱藏" width="100">
          <template v-slot="{ row }">
            <el-switch v-model="row.hidden" @change="toggleHidden(row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template v-slot="{ row }">
            <el-button type="primary" size="mini" @click="editProject(row)">編輯</el-button>
            <el-button type="danger" size="mini" @click="deleteProject(row)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </ElTableDraggable>
  </div>
</template>

<script>

import ElTableDraggable from "el-table-draggable";
// import Vue from "vue";

export default {
  components: {
    ElTableDraggable
  },
  data() {
    return {
      projects: []  // 儲存從API獲取的項目數據
    };
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    fetchProjects() {
      this.$axios.get('/projects')
        .then(response => {
          this.projects = response.data.map((project, index) => ({
            ...project,
            index: index + 1  // 添加排序序號
          }));
        })
        .catch(error => {
          console.error('Error fetching projects:', error);
          this.$message.error('獲取項目數據失敗');
        });
    },
    toggleFlag(project){
      const updatedProject = {
       ...project,
        flag: project.flag
      };

      this.$axios.put(`/projects/${project._id}`, updatedProject)
        .then(() => {
          this.$message.success('項目標籤狀態已更新');
          this.fetchProjects();  // 重新獲取最新的項目列表
        })
        .catch(error => {
          console.error('Error updating project flag status:', error);
          this.$message.error('更新項目標籤狀態失敗');
        });
    },
    toggleProject(project) {
      const enabledProjects = this.projects.filter(p => p.enabled === true).length;
      if (enabledProjects > 4 && project.enabled) {
        this.$message.error('啟用的項目數量不能超過四個');
        // 取消切換
        project.enabled = false;
        return;
      }

      const updatedProject = {
        ...project,
        enabled: project.enabled
      };

      this.$axios.put(`/projects/${project._id}`, updatedProject)
        .then(() => {
          this.$message.success('項目狀態已更新');
          this.fetchProjects();  // 重新獲取最新的項目列表
        })
        .catch(error => {
          console.error('Error updating project status:', error);
          this.$message.error('更新項目狀態失敗');
        });
    },
    toggleHidden(project) {
      const updatedProject = {
        ...project,
        hidden: project.hidden
      };

      this.$axios.put(`/projects/${project._id}`, updatedProject)
        .then(() => {
          this.$message.success('項目隱藏狀態已更新');
          this.fetchProjects();  // 重新獲取最新的項目列表
        })
        .catch(error => {
          console.error('Error updating project hidden status:', error);
          this.$message.error('更新項目隱藏狀態失敗');
        });
    },
    deleteProject(project) {
      this.$confirm('確認要刪除這個項目嗎?', '警告', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.delete(`/projects/${project._id}`)
          .then(() => {
            this.$message.success('項目已刪除');
            this.fetchProjects();  // 重新獲取最新的項目列表
          })
          .catch(error => {
            console.error('Error deleting project:', error);
            this.$message.error('刪除項目失敗');
          });
      }).catch(() => {
        this.$message.info('取消刪除操作');
      });
    },
    editProject(project) {
      this.$router.push({ name: 'EditProject', params: { id: project._id } });
    },
    onEnd(event) {  // eslint-disable-line no-unused-vars
      this.$nextTick(() => {
        // 更新項目順序
        // this.projects.forEach((project, index) => {
        //   console.log(project);
        //   project.index = index + 1;
        //   // Vue.set(project, 'index', index + 1);
        // });
        // 在這裡發送 API 請求以保存新順序
        this.saveProjectOrder();
      });
    },
    saveProjectOrder() {
      const orderedProjects = this.projects.map((project, index) => ({
        _id: project._id,
        order: index + 1
      }));
      this.$axios.put('/projects/order/update', orderedProjects)
        .then(() => {
          this.$message.success('項目順序已保存');
          this.fetchProjects();  // 重新獲取最新的項目列表
        })
        .catch(error => {
          console.error('Error saving project order:', error);
          this.$message.error('保存項目順序失敗');
        });
    }
  }
}
</script>

<style scoped>
.project-image {
  width: auto;
  height: 100px;
}
</style>