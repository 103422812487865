<template>
  <div class="teams-list">
    <ElTableDraggable v-model="teams" @onEnd="onEnd">
      <el-table :data="teams" row-key="index" border style="width: 100%">
        <el-table-column prop="index" label="排序" width="80"/>
        <el-table-column label="圖片">
          <template v-slot="{ row }">
            <img :src="'/images/' + row.image" class="team-image" />
          </template>
        </el-table-column>
        <el-tabl ㄙㄥe-column prop="name" label="姓名" width="180"/>
        <el-table-column prop="title" label="職稱" width="180"/>
        <el-table-column label="操作" width="240">
          <template v-slot="{ row }">
            <el-button type="primary" size="mini" @click="openEditDialog(row)">編輯</el-button>
            <el-button type="danger" size="mini" @click="deleteTeam(row)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </ElTableDraggable>
    <el-dialog title="編輯團隊成員" :visible.sync="editDialogVisible" width="30%">
      <el-form :model="editTeam">
        <el-form-item label="姓名">ㄙㄥ
          <el-input v-model="editTeam.name"></el-input>
        </el-form-item>
        <el-form-item label="職稱">
          <el-input v-model="editTeam.title"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateTeam">確認</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import ElTableDraggable from "el-table-draggable";

export default {
  components: {
    ElTableDraggable
  },
  data() {
    return {
      teams: [],  // 儲存從API獲取的團隊數據
      editDialogVisible: false,  // 控制編輯對話框的顯示
      editTeam: {},  // 儲存當前編輯的團隊成員數據
    };
  },
  created() {
    this.fetchTeams();
  },
  methods: {
    fetchTeams() {
      this.$axios.get('/uploadTeams')
        .then(response => {
          this.teams = response.data.map((team, index) => ({
            ...team,
            index: index + 1  // 添加排序序號
          }));
        })
        .catch(error => {
          console.error('Error fetching teams:', error);
          this.$message.error('獲取團隊數據失敗');
        });
    },
    openEditDialog(team) {
      this.editTeam = { ...team };  // 複製當前編輯的團隊成員數據
      this.editDialogVisible = true;  // 顯示編輯對話框
    },
    onEnd(event) {  // eslint-disable-line no-unused-vars
      this.$nextTick(()=> {
        this.saveTeamOrder();
      });
    },
    saveTeamOrder() {
      const orderedTeams = this.teams.map((team, index) => ({
        _id: team._id,
        order: index + 1
      }));
      this.$axios.put('/teams/order/update', orderedTeams)
        .then(() => {
          this.$message.success('團隊順序以保存');
          this.fetchTeams();
        })
        .catch(error => {
          console.error('Error saving teams order:', error);
          this.$message.error('保存團隊順序失敗');
        });
    },
    updateTeam() {
      this.$axios.put(`/uploadTeams/${this.editTeam._id}`, this.editTeam)
        .then(() => {
          this.$message.success('團隊成員已更新');
          this.fetchTeams();  // 重新獲取最新的團隊列表
          this.editDialogVisible = false;  // 隱藏編輯對話框
        })
        .catch(error => {
          console.error('Error updating team member:', error);
          this.$message.error('更新團隊成員失敗');
        });
    },
    deleteTeam(team) {
      this.$confirm('確定要刪除這個團隊成員嗎？', '警告', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.delete(`/uploadTeams/${team._id}`)
          .then(() => {
            this.$message.success('團隊成員已刪除');
            this.fetchTeams();  // 重新獲取最新的團隊列表
          })
          .catch(error => {
            console.error('Error deleting team member:', error);
            this.$message.error('刪除團隊成員失敗');
          });
      }).catch(() => {
        this.$message.info('取消刪除操作');
      });
    }
  }
}
</script>

<style scoped>
.team-image {
  width: 100px;
  height: auto;
}
</style>
