<template>
  <div class="login-container">
    <div class="logo">Brand</div>
    <el-form ref="loginForm">
      <el-form-item label="用戶名" prop="username" required>
        <el-input v-model="loginForm.username" placeholder="請輸入用戶名" auto-complete="username"></el-input>
      </el-form-item>
      <el-form-item label="密碼" prop="password" required>
        <el-input type="password" v-model="loginForm.password" placeholder="請輸入密碼" auto-complete="current-password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleLogin">登入</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    handleLogin() { 
      if (this.loginForm.username === 'admin' && this.loginForm.password === 'admin') {
        localStorage.setItem('userLoggedIn', 'true');
        this.$emit('login-success'); // 发射登录成功事件
        this.$router.push('/products/list');
        this.$message.success('登录成功！');
      } else {
        this.$message.error('用戶名或密碼錯誤！');
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: #fff;
}
</style>

